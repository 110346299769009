import { css } from '@emotion/react'
import { graphql, useStaticQuery } from 'gatsby'
import { ComponentPropsWithoutRef, Fragment, useState } from 'react'

import { DatoStructuredText } from '@/components/DatoStructuredText'
import { DatoStructuredTextLink } from '@/features/dato-link'
import { useSearchContext } from '@/features/search'
import { useElementHeight } from '@/hooks/useElementRect'
import { colors } from '@/theme/variables'

import { useMainNavContext } from '../../contexts/mainNavContext'

export const Alert = ({
  ...props
}: ComponentPropsWithoutRef<'div'>): JSX.Element => {
  const { data } = useStaticQuery<Queries.AlertQuery>(graphql`
    query Alert {
      data: datoCmsAlert {
        __typename
        id: originalId
        alertActive
        alertText {
          value
          links {
            ... on DatoCmsTertiaryArticle {
              ...TertiaryArticle
            }
          }
        }
      }
    }
  `)
  const [heightRef, setHeightRef] = useState<HTMLDivElement | null>(
    null
  )
  const height = useElementHeight(heightRef)
  const { activeNavItemId, mobileNavIsOpen } = useMainNavContext()
  const { searchIsOpen } = useSearchContext()
  const styles = {
    alertContainer: css`
      position: relative;
      z-index: 8;
      max-height: ${height}px;
      transition: max-height 100ms ease;
      ${(activeNavItemId || mobileNavIsOpen || searchIsOpen) &&
      css`
        max-height: 0px;
      `}
    `,
    inner: css`
      display: flex;
      justify-content: center;
      align-items: flex-start;
      column-gap: 0.5em;
      background: #000;
      color: #fff;
      font-style: italic;
      line-height: 1.333;
      padding: 0 var(--gtr-36);
    `,
    leadIn: css`
      font-weight: 700;
      color: ${colors.redLight};
      text-transform: uppercase;
      margin: 1em 0;
    `,
    body: css``,
    link: css`
      display: inline-block;
      color: #fff;
      @media (hover: hover) {
        &:hover {
          color: ${colors.redLight};
        }
      }
    `,
  }
  if (data?.alertActive) {
    return (
      <div
        css={styles.alertContainer}
        {...props}
      >
        <div
          css={styles.inner}
          ref={setHeightRef}
        >
          <span css={styles.leadIn}>Alert: </span>
          <div css={styles.body}>
            <DatoStructuredText
              data={data?.alertText}
              renderLinkToRecord={({
                record,
                children,
                transformedMeta,
              }) => (
                <DatoStructuredTextLink
                  css={styles.link}
                  data={record}
                  {...transformedMeta}
                >
                  {children}
                </DatoStructuredTextLink>
              )}
            />
          </div>
        </div>
      </div>
    )
  } else return <Fragment />
}
